@import '_normalize.scss';
@import '_variables.scss';

body {
    background-color: $off-white;
    color: $grey;
    font-family: $print;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}
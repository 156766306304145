@import '_variables.scss';
@import '_mixins.scss';

header{
  @include flex(space-evenly, center);
  background-color: $off-white;
  height: 20vh;

  a {
    text-decoration: none;
  }

  h1 {
    color: $grey;
    font-family: $cursive;
    font-size: 64px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: .5s;
      color: $gold;
    }
  }

  nav {
    @include flex(space-evenly, center);
    height: 100%;
    width: 45%;
    
    a {
      color: $grey;
      font-size: 16px;
      text-decoration: none;
      padding: 2% 4%;
      border-radius: 50px;
      cursor: pointer;

      &:hover {
        transform: scale(1.5);
        transition: .5s;
        color: $red;
      } 
    }
  }
}

main {
  @include flex(space-evenly, center);
  width: 100%;

  .error {
    font-size: 30px;
    color: $grey;
  }
}

@media screen and (max-width: 850px) {
  header {
    @include flex(space-evenly, center, column);
    height: 25vh;

    h1 {
      font-size: 46px;
    }

    nav {
      height: 50%;
      width: 90%;
    }
  }
}
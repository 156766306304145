@import '_variables.scss';
@import '_mixins.scss';

.other-work-container {
  @include flex(space-evenly, center, column);
  width: 100%;

  .works-container {
    width: 100%;
    display: grid;
    grid-gap: 3%;
    grid-template-columns: repeat(auto-fill, minmax(250px, auto));
    justify-content: space-evenly;
  }

  .work-link {
    color: $grey;
    font-size: 14px;
    text-align: center;
    border-radius: 50px;
    padding: 1% 3%;
    margin: 2% auto;
    cursor: pointer;

    &:hover {
      transform: scale(1.25);
      transition: .5s;
      color: $green;
    }
  }

  .work {
    @include flex(center, center, column);

    .work-img {
      width: 200px;
      height: 300px;
      margin: 4% auto;
      box-shadow: 0.25px 2px 4px 0.5px $grey;
    }

    .work-link {
      padding: 3% 9%;
    }
  }
}

@media screen and (max-width: 550px) {
  .other-work-container {
    .works-container {
      grid-gap: 1%;

      .work {
        .work-link {
          margin-bottom: 10%;
        }
      }
    }
  }
}
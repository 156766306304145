@import '_mixins.scss';
@import '_variables.scss';

/* Page layout */
.book-btn-container {
  @include flex(space-between, center);
  width: 90%;
}

/* Buttons */
button {
  color: $grey;
  background-color: $off-white;
  font-size: 20px;
  border: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.5);
    transition: .5s;
    color: $green;
  } 
}

.no-book {
  display: none;
}

/* Book */
.book {
  position: relative;
  width: 350px;
  height: 500px;
  transition: transform 0.5s;
}

.front .f1,
.back .b4 {
  h1 {
    font-family: $cursive;
    font-size: 28px;
    max-width: 80%;
  }
}

.back .b1,
.front .f2,
.back .b2,
.front .f3,
.back .b3,
.front .f4 {
  @include flex(space-evenly, center, column);
  
  h1 {
    font-family: $cursive;
    font-size: 22px;
  }
  
  p {
    max-width: 80%;
    line-height: 24px;
  }
}

.book-cover-img {
  width: 252px;
  height: 337px;
  box-shadow: 0.25px 2px 4px 0.5px $grey;
}

.paper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    perspective: 1500px;
}

.front,
.back {
    background-color: white;
    border: 1px solid $grey;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left;
    transition: transform 0.5s;
}

.front {
    z-index: 1;
    backface-visibility: hidden;
    border-left: 3px solid $grey;
}

.back {
    z-index: 0;
}

.front-content,
.back-content {
    width: 100%;
    height: 100%;
    @include flex(center, center);
}

.back-content {
    transform: rotateY(180deg);
}

/* Paper stack order */
.p1 {
  z-index: 4;
}

.p2 {
  z-index: 3;
}

.p3 {
  z-index: 2;
}

.p4 {
  z-index: 1;
}

/* Paper flip effect */
.flipped .front,
.flipped .back {
  transform: rotateY(-180deg);
}

.flippedP1 {
  z-index: 1;
}

.flippedP2 {
  z-index: 2;
}

.flippedP3 {
  z-index: 3;
}

.flippedP4 {
  z-index: 4;
}

/* Book open & close and button effects */
.openBook {
  transform: translateX(50%);
}

.closeBookBeginning {
  transform: translateX(0%);
}

.closeBookEnd {
  transform: translateX(100%);
}

@media screen and (max-width: 1000px) {
  .book-btn-container {
    @include flex(space-between, center, column);
    width: 90%;
    margin-bottom: 2%;
    
    .book {
      margin: 2%;
    }
  }
}

@media screen and (max-width: 750px) {
  .book-btn-container {
    @include flex(space-between, center, column);
    width: 90%;
    margin-bottom: 2%;
    
    .book {
      width: 245px;
      height: 350px;
      margin: 2%;
    }

    .front .f1,
    .back .b4 {
      h1 {
        font-size: 20px;
      }
    }

    .back .b1,
    .front .f2,
    .back .b2,
    .front .f3,
    .back .b3,
    .front .f4 {
      h1 {
        font-size: 16px;
      }
      
      p {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .book-cover-img {
      width: 176px;
      height: 236px;
    }
  }
}

@media screen and (max-width: 500px) {
  .book-btn-container {
    margin-top: 10%;
  }

  .book {
    display: none;
  }

  .no-book {
    display: flex;
    color: $red;
    margin: 10% auto;
  }
}
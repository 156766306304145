@import '_variables.scss';

@mixin flex($justify, $align, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin button() {
  background-color: $grey;
  color: $off-white;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  padding: 1% 2%;
  cursor: pointer;

  &:hover {
    transform: scale(0.80);
    transition: .5s;
    opacity: 0.8;
  }
}
@import '_variables.scss';
@import '_mixins.scss';

.tech-projects-container {
  display: grid;
  grid-gap: 3%;
  grid-template-columns: repeat(auto-fill, 1fr);
  justify-content: space-evenly;
  width: 90%;
}

.projects-note {
  text-align: center;
}

.project {
  @include flex(flex-start, center);
  width: 100%;

  .project-img-container {
    @include flex(center, center, column);
    width: auto;
    margin-right: 2%;

    img {
      height: 315px;
      width: 522px;
      box-shadow: 0.25px 2px 4px 0.5px $grey;
      margin: 3% auto;
    }
  }

  .project-details-container {
    @include flex(center, center, column);
    width: auto;

    .project-title {
      font-size: 26px;
    }

    .two-repo-links {
      @include flex(space-evenly, center);
      width: 50%;
    }

    p {
      width: 90%;
      margin: 1% auto;
      line-height: 22px;
    }

    a {
      color: $grey;
      font-size: 14px;
      text-align: center;
      border-radius: 50px;
      padding: 1% 3%;
      cursor: pointer;

      &:hover {
        transform: scale(1.25);
        transition: .5s;
        color: $green;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .tech-projects-container {
    grid-gap: 1.5%;  
  }
  
  .project {
    @include flex(space-evenly, center, column);

    .project-img-container {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .tech-projects-container {
    margin-top: 5%;
    grid-gap: 0.5%;
  }

  .project {
    .project-img-container {
      img {
        max-width: 90%;
        max-height: 60%;
        margin: 1% auto;
      }
    }

    .project-details-container {
      margin-bottom: 10%;
    }
  }
}